import axios from "axios";
import { pluginBackendUrl } from "../constants";
import { PopUp } from "helpers/PopUp";
import { ContactPoint } from "components/ContactPointsReport";
import { Dashboard } from "@grafana/schema";
import { defaultTimeZone } from "./dashboardTimeSettings";

interface ExtendedDashboard extends Dashboard {
    configIdentifier?: string;
}

export const scheduleReport = async (
    dashboard: ExtendedDashboard | null,
    contactPoint: ContactPoint | null,
    cronExpression: string | null,
    fetchReports: () => void,
    clearSelections: () => void
): Promise<boolean> => {

    if (!cronExpression) {
        PopUp('Please select report schedule.', false);
        return false;
    }

    if (!contactPoint?.value) {
        PopUp(contactPoint?.uid === "" ? 'Please check contact point configuration.' : 'Please select contact point.', false);
        return false;
    }

    if (!dashboard?.uid) {
        PopUp('Please select a dashboard.', false);
        return false;
    }

    const cronParts = cronExpression?.split(' ');
    const cronExpressionSliced = cronParts?.slice(0, 5).join(' ');

    const timezone = defaultTimeZone(dashboard.timezone || '');
    const activeOrgId = localStorage.getItem('orgId');

    const postData = {
        dashUid: dashboard?.uid,
        dashTitle: dashboard?.title,
        scheduleTime: cronExpressionSliced,
        contactPoint: contactPoint,
        timeZone: timezone,
        timeRange: {
            from: dashboard.time?.from,
            to: dashboard.time?.to
        },
        orgId: activeOrgId,
        configIdentifier: dashboard?.configIdentifier === undefined ? '' : dashboard?.configIdentifier
    }
    console.log('postData', postData);

    try {
        await axios.post(`${pluginBackendUrl}schedule-report`, postData);
        fetchReports();
        clearSelections();
        PopUp('Report scheduled successfully.', true);
        return true;
    } catch (error) {
        PopUp('Error scheduling report.', false);
        console.error('Error scheduling report:', error);
        return false;
    }
};
