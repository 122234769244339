import React from 'react';
import axios from 'axios';
import { PopUp } from 'helpers/PopUp';
import { Button } from '@grafana/ui';
import { useDropdowns } from 'components/App/DropdownProvider';
import { defaultTimeZone } from 'utils/dashboardTimeSettings';
import { getContactPointEmails } from 'utils/contactPointEmails';
import { pluginBackendUrl } from '../../constants';

export function AddOnAlert(): JSX.Element {
    const { selectedContactPoint, selectedDashboard, fetchContactPoints, setSelectedDashboard, setSelectedContactPoint } = useDropdowns();

    const windowUrl = window.location.href;
    const urlObject = new URL(windowUrl);
    const portAndIP = urlObject.origin;

    const handleURLConstruction = async () => {
        if (!selectedDashboard?.uid || !selectedContactPoint?.uid) {
            PopUp('Please select both a dashboard and a contact point.', false);
            return;
        }

        try {
            // Use Promise.all to fetch all data simultaneously
            const [contactPointEmails] = await Promise.all([
                getContactPointEmails(selectedContactPoint.uid),
            ]);

            const timezone = defaultTimeZone(selectedDashboard.timezone || '');
            const activeOrgId = localStorage.getItem('orgId');
            const params = new URLSearchParams({
                dashUid: selectedDashboard?.uid,
                dashName: selectedDashboard?.title as string,
                timeZone: timezone,
                timeRangeFrom: selectedDashboard.time?.from as string,
                timeRangeTo: selectedDashboard.time?.to as string,
                orgId: activeOrgId as string
            });

            const url = `${portAndIP}/${pluginBackendUrl}alert-report?${params.toString()}&addresses=${contactPointEmails}`;
            console.log(url); // This log can be removed in production
            return url;
        } catch (error) {
            console.error('Error constructing URL:', error);
            PopUp('Failed to construct the URL for the alert report.', false);
            return;
        }
    }

    const validateInputs = () => {
        if (!selectedDashboard?.uid) {
            return PopUp('Please select a dashboard.', false);
        }

        if (!selectedContactPoint?.value) {
            return PopUp('Please select a contact point.', false);
        }
        return true;
    }

    const addReportContactPoint = async () => {
        if (!validateInputs()) {
            return;
        }

        try {
            const url = await handleURLConstruction();
            if (!url) return; // Ensure URL was constructed

            let updatedContactPointData = {
                name: selectedContactPoint?.name,
                type: "webhook",
                settings: {
                    httpMethod: "POST",
                    url: url,
                },
                disableResolveMessage: false
            };

            await axios.post("api/v1/provisioning/contact-points", updatedContactPointData, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Disable-Provenance': 'true'
                }
            });
            fetchContactPoints();
            clearSelections();

            PopUp('New contact point added successfully.', true);

        } catch (error) {
            console.error('Error adding new contact point:', error);
            PopUp('Error adding new contact point.', false);
        }
    }

    const clearSelections = () => {
        setSelectedDashboard(null);
        setSelectedContactPoint(null);
    };

    return (
        <div>
            <Button onClick={addReportContactPoint} variant="primary">
                Add report to contact point
            </Button>
        </div>
    );
}

export default AddOnAlert;