import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { AppConfig } from "../AppConfig";
import { useNavigation, prefixRoute } from "../../utils/utils.routing";
import { ROUTES } from "../../constants";
import { OnDemand } from "pages/OnDemand";
import { OnPeriod } from "pages/OnPeriod";
import { OnAlert } from "pages/OnAlert";
import { Status } from "pages/Status/Status";

export const Routes = () => {
  useNavigation();

  return (
    <Switch>
      <Route
        exact
        path={prefixRoute(`${ROUTES.Config}`)}
        component={AppConfig}
      />
      {/* Status page */}
      <Route path={prefixRoute(`${ROUTES.Status}`)} component={Status} />
      <Route path={prefixRoute(`${ROUTES.OnDemand}`)} component={OnDemand} />
      <Route path={prefixRoute(`${ROUTES.OnPeriod}`)} component={OnPeriod} />
      <Route path={prefixRoute(`${ROUTES.OnAlert}`)} component={OnAlert} />
    </Switch>
  );
};
