import React from 'react';
import ContactPointsDropdown from 'components/ContactPointsDropdown';
import DashboardDropdown from 'components/DashboardDropdown';
import ContactPointsReport, { ContactPointSettings } from 'components/ContactPointsReport';
import { useDropdowns } from 'components/App/DropdownProvider';
import AddOnAlert from './AddOnAlert';

export interface ContactPoint {
    label: string;
    name: string;
    settings: ContactPointSettings;
    type: string;
    uid: string;
    value: string;
    addresses: string;
}

export function OnAlert(): JSX.Element {
    const { fetchContactPoints, webhookContactPoints, setWebhookContactPoints } = useDropdowns();

    return (
        <div>
            <DashboardDropdown />
            <ContactPointsDropdown webhookContactPoints={webhookContactPoints} />
            {/* Add report button */}
            <AddOnAlert></AddOnAlert>

            <ContactPointsReport webhookContactPoints={webhookContactPoints} setWebhookContactPoints={setWebhookContactPoints} fetchContactPoints={fetchContactPoints} ></ContactPointsReport>


        </div>
    );
}

export default OnAlert;
