import React, { createContext, useState, useEffect, useContext, useMemo, ReactNode, useCallback } from 'react';
import axios from 'axios';
import { PopUp } from 'helpers/PopUp';
import { ContactPoint } from 'components/ContactPointsReport';
import { Dashboard, DashboardLink } from '@grafana/schema';
import { DashboardProps } from '@grafana/data';

// Extend the Dashboard interface to include hasLink
export interface ExtendedDashboard extends DashboardProps {
    hasLink: boolean;
    timeZone?: string;
    configIdentifier: string;
    tags: string[];
    links: DashboardLink[]
}

// Define the shape of the context value
interface DropdownContextType {
    dashboardOptions: ExtendedDashboard[];
    emailContactPoints: ContactPoint[];
    webhookContactPoints: ContactPoint[];
    selectedDashboard: Dashboard | null;
    selectedContactPoint: ContactPoint | null;
    setSelectedDashboard: (value: Dashboard | null) => void;
    setSelectedContactPoint: (value: ContactPoint | null) => void;
    setWebhookContactPoints: (value: ContactPoint[]) => void;
    fetchContactPoints: () => void;
    cronExpression: string | null;
    setCronExpression: (value: string | null) => void;
    isUpdating: boolean;
    setIsUpdating: (value: boolean) => void;
    getDashboardData: (dashboardsUid: string) => void;
    error: any;
}

const DropdownContext = createContext<DropdownContextType>({
    dashboardOptions: [],
    emailContactPoints: [],
    webhookContactPoints: [],
    selectedDashboard: null,
    selectedContactPoint: null,
    setSelectedDashboard: () => { },
    setSelectedContactPoint: () => { },
    fetchContactPoints: () => { },
    setWebhookContactPoints: () => { },
    cronExpression: null,
    setCronExpression: () => { },
    isUpdating: false,
    setIsUpdating: () => { },
    getDashboardData: () => { },
    error: null,
});

export const useDropdowns = () => {
    const context = useContext(DropdownContext);
    if (!context) {
        throw new Error('useDropdowns must be used within a DropdownProvider');
    }
    return context;
};

export const DropdownProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Dashboards dropdown
    const [dashboardOptions, setDashboardOptions] = useState<ExtendedDashboard[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    // Contact points
    const [emailContactPoints, setEmailContactPoints] = useState<ContactPoint[]>([]);
    const [webhookContactPoints, setWebhookContactPoints] = useState<ContactPoint[]>([]);

    //Contact point dropdown
    const [selectedContactPoint, setSelectedContactPoint] = useState<ContactPoint | null>(null);
    const [error, setError] = useState<any>(null);

    // Report schedule Contact points
    const [cronExpression, setCronExpression] = useState<string | null>(null);

    const fetchDashboards = async () => {
        try {
            const response = await axios.get('/api/search?type=dash-db');
            const dashboards = response.data.map((dashboard: any) => ({
                label: dashboard.title,
                value: dashboard.uid,
                ...dashboard,
            }));
            setDashboardOptions(dashboards);
        } catch (err) {
            setError(err);
            PopUp("We are experiencing technical issues.Please contact technical support (EO1)" as string, false);
        }
    };



    const getDashboardData = useCallback(async (dashboardsUid: string) => {

        // Use Promise.all to handle multiple async operations
        // const dashboardPromises = dashboards.map(async (dashboard) => {
        try {
            const response = await axios.get(`/api/dashboards/uid/${dashboardsUid}`);
            const checkLink = response.data.dashboard.links?.some((link: DashboardLink) => link?.title === "Report");
            console.log(response.data.dashboard, 'response.data.dashboard');
            const responseData = {
                ...response.data.dashboard,
                hasLink: checkLink,
            };
            setSelectedDashboard(responseData);
        } catch (err) {
            PopUp('Failed to process dashboard data.', false);
        }
    }, []);

    const fetchContactPoints = useCallback(async () => {
        try {
            const response = await axios.get<ContactPoint[]>('api/v1/provisioning/contact-points');
            const contactPoints = response.data;

            const emailContactPoints = contactPoints
                .filter((contactPoint) => contactPoint.type === 'email')
                .map((contactPoint) => ({
                    ...contactPoint,
                    label: contactPoint.name,
                    value: contactPoint.uid,
                    addresses: contactPoint.settings.addresses,
                }));

            const webhookContactPoints = contactPoints
                .filter((contactPoint) => contactPoint.type === 'webhook')
                .map((contactPoint) => {
                    const urlParams = new URLSearchParams(contactPoint.settings.url);
                    const dashboardName = urlParams.get('dashName') ? urlParams.get('dashName') : '';
                    const associatedEmail = emailContactPoints.find(email => email.label === contactPoint.name);
                    const emailAddresses = associatedEmail ? associatedEmail.addresses : '';
                    return {
                        ...contactPoint,
                        dashboardName,
                        emailAddresses,
                    };
                });

            setEmailContactPoints(emailContactPoints);
            console.log(emailContactPoints, 'emailContactPoints');

            setWebhookContactPoints(webhookContactPoints);
        } catch (err) {
            setError(err);
        }
    }, []);

    const fetchOrgId = async () => {
        try {
            const activeOrg = await axios.get('api/org/');
            const orgId = activeOrg?.data?.id.toString();
            console.log(orgId);
            localStorage.setItem('orgId', orgId);
        } catch (error) {
            console.error('Failed to fetch organization ID:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchDashboards(), fetchContactPoints(), fetchOrgId()]);
        };
        fetchData();
    }, []);

    const value = useMemo(() => ({
        dashboardOptions,
        emailContactPoints,
        webhookContactPoints,
        selectedDashboard,
        selectedContactPoint,
        setSelectedDashboard,
        setSelectedContactPoint,
        fetchContactPoints,
        setWebhookContactPoints,
        cronExpression,
        setCronExpression,
        isUpdating,
        setIsUpdating,
        getDashboardData,
        error,
    }), [
        dashboardOptions,
        emailContactPoints,
        webhookContactPoints,
        selectedDashboard,
        selectedContactPoint,
        setSelectedDashboard,
        setCronExpression,
        cronExpression,
        fetchContactPoints,
        setWebhookContactPoints,
        isUpdating,
        setIsUpdating,
        getDashboardData,
        error,
    ]);

    return (
        <DropdownContext.Provider value={value}>
            {children}
        </DropdownContext.Provider>
    );
};
