import React from 'react';
import axios from 'axios';
import { PopUp } from 'helpers/PopUp';
import CustomTable from 'components/CustomComponents/CustomTable';
import { Button } from '@grafana/ui';


export interface ContactPointSettings {
    addresses: string;
    singleEmail: boolean;
    url: string;
}

export interface ContactPoint {
    name: string;
    type: string;
    settings: ContactPointSettings;
    uid: string;
    dashboardName: string | null;
    emailAddresses?: string;
    value?: string;
    label?: string;
}

interface ContactPointsReportProps {
    setWebhookContactPoints: (contactPoints: any) => void;
    webhookContactPoints: ContactPoint[];
    fetchContactPoints: () => void;
}

export function ContactPointsReport({ webhookContactPoints, fetchContactPoints }: ContactPointsReportProps): JSX.Element {


    const handleRemove = async (uid: string) => {
        try {
            await axios.delete(`api/v1/provisioning/contact-points/${uid}`);
            PopUp('Contact point removed successfully.', true);
            fetchContactPoints();
        } catch (error) {
            console.error('Failed to remove contact point:', error);
            PopUp('Failed to remove contact point.', false);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Dashboard Name',
            dataIndex: 'dashboardName',
            key: 'dashboardName',
        },
        {
            title: 'Email Address',
            dataIndex: 'emailAddresses',
            key: 'emailAddresses',
        },
        {
            title: 'Actions',
            key: 'actions',
            className: 'text-center',
            render: (record: ContactPoint) => (
                <>
                    <Button key="remove" onClick={() => handleRemove(record?.uid === undefined ? "" : record.uid)}>
                        Remove
                    </Button>
                </>
            ),
        }
    ];

    return (
        <div>
            <CustomTable
                contactPoints={webhookContactPoints}
                dataTable={webhookContactPoints}
                handleRemove={handleRemove}
                columns={columns}
                propertyKey="uid"
            />
        </div>
    );
}



export default ContactPointsReport;
