import React from 'react';
import { Field, Select } from '@grafana/ui';
import { useDropdowns } from './App/DropdownProvider';

const DashboardDropdown: React.FC = () => {
    const { dashboardOptions, selectedDashboard, setSelectedDashboard, setCronExpression, isUpdating, getDashboardData } = useDropdowns();

    const handleDashboardChange = (selected: any) => {
        setSelectedDashboard(selected);
        setCronExpression(null);

        getDashboardData(selected?.uid);
    };

    return (
        <div>
            <Field
                label="Select Dashboard"
                description="Select the dashboard you want to generate a report for."
            >
                <Select
                    key={selectedDashboard?.uid}
                    width={60}
                    options={dashboardOptions}
                    onChange={handleDashboardChange}
                    placeholder="Select a dashboard"
                    value={selectedDashboard?.uid}
                    disabled={isUpdating}
                />
            </Field>
        </div>
    );
};

export default DashboardDropdown;
