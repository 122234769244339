import React, { useState } from 'react';
import { Table } from 'antd';
import { useStyles2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import PaginationControls from './PaginationControls';

interface ContactPointsReportProps {
  contactPoints: any;
  dataTable: any;
  handleRemove: (uid: string) => void;
  columns: any[];
  filterFunction?: (item: any) => boolean;
  propertyKey: string;
}

export function CustomTable({
  dataTable,
  handleRemove,
  columns,
  filterFunction,
  propertyKey,
}: ContactPointsReportProps): JSX.Element {
  const s = useStyles2(getStyles);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // Handle page change
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) { setPageSize(pageSize) };
  };

  const modifiedHandleRemove = (uid: string) => {
    handleRemove(uid);
    // After removing, check if the current page still has items
    const newLength = dataTable.length - 1;
    if ((currentPage - 1) * pageSize >= newLength && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const filteredData = filterFunction ? dataTable?.filter(filterFunction) : dataTable;

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const enhancedColumns = columns.map(column => {
    if (column.key === 'actions') {
      return {
        ...column,
        render: (record: any) => (
          <>
            {React.Children.map(column.render(record).props.children, child => {
              if (child.key === 'remove') {
                return React.cloneElement(child, { onClick: () => modifiedHandleRemove(record[propertyKey]) },
                );
              }
              return child;
            })}
          </>
        )
      };
    }
    return column;
  });

  return (
    <div className={cx(s.marginBottom)}>
      <Table
        rowKey="uid"
        dataSource={paginatedData}
        columns={enhancedColumns}
        loading={false}
        pagination={false}
        className={cx(s.table)}
      />
      <PaginationControls
        currentPage={currentPage}
        pageSize={pageSize}
        totalItems={dataTable.length}
        onPageChange={handlePageChange}
      />
    </div>
  );
}


const getStyles = (theme: GrafanaTheme2) => ({
  textColor: css`
    color: ${theme.colors.text.primary};
  `,
  marginBottom: css`
    margin-bottom: ${theme.spacing(1)};
    margin-top: ${theme.spacing(1)};
  `,
  table: css`
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.text.primary}; /* Apply border to the whole table */
    .ant-table-thead > tr > th {
      background-color: ${theme.colors.background.primary};
      color: ${theme.colors.text.secondary};
      border-color: ${theme.colors.text.primary};
    }

    .ant-table-tbody > tr > td {
      color: ${theme.colors.text.primary};
      border-color: ${theme.colors.text.primary};
    }

    /* Apply hover styles */
    .ant-table-tbody > tr:hover > td {
      background-color: ${theme.colors.background.secondary}; /* Change background color on hover */
      border-color: ${theme.colors.text.primary};
    }

    .ant-table-cell {
      color: ${theme.colors.text.secondary};
      background-color: ${theme.colors.background.secondary};
      border-color: ${theme.colors.text.primary};
    }

    .ant-table-cell-row-hover {
      background-color: ${theme.colors.background.primary} !important; 
      border-color: ${theme.colors.text.primary};
    }

    .ant-table-cell-hover {
      color: ${theme.colors.text.secondary};
      background-color: ${theme.colors.background.secondary};
      border-color: ${theme.colors.text.primary};
    }

    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background-color: ${theme.colors.background.primary};
      border-color: ${theme.colors.text.primary}; 
    }

    .ant-empty-description {
      color: ${theme.colors.text.primary};
    }
    .text-center {
      text-align: center !important;
    }
  `,
  button: css`
    color: ${theme.colors.text.primary};
  `,
});

export default CustomTable;

