import { NavModelItem } from "@grafana/data";
import pluginJson from "./plugin.json";

export const PLUGIN_ID = `${pluginJson.id}`;
export const PLUGIN_BASE_URL = `/a/${PLUGIN_ID}`;

export enum ROUTES {
  Status = "status",
  Config = "config",
  ScheduledReports = "scheduled-reports",
  OnDemand = "on-demand",
  OnPeriod = "on-period",
  OnAlert = "on-alert",
  GoBack = "go-back",
}

export const NAVIGATION_TITLE = "Grafana Reporting App";
export const NAVIGATION_SUBTITLE = "A Grafana plugin app that generates PDF reports from Grafana dashboards";

// Corrected the navigation item URLs and paths
export const NAVIGATION: Record<string, NavModelItem> = {
  [ROUTES.Status]: {
    id: ROUTES.Status,
    text: "Status",
    icon: "heart",
    url: `${PLUGIN_BASE_URL}/status`,
  },
  [ROUTES.Config]: {
    id: ROUTES.Config,
    text: "Configuration",
    icon: "cog",
    url: `plugins/${PLUGIN_ID}`,
  },
  [ROUTES.OnDemand]: {
    id: ROUTES.OnDemand,
    text: "On Demand",
    icon: "document-info",
    url: `${PLUGIN_BASE_URL}/on-demand`,
  },
  [ROUTES.OnPeriod]: {
    id: ROUTES.OnPeriod,
    text: "On Period",
    icon: "clock-nine",
    url: `${PLUGIN_BASE_URL}/on-period`,
  },
  [ROUTES.OnAlert]: {
    id: ROUTES.OnAlert,
    text: "On Alert",
    icon: "exclamation-triangle",
    url: `${PLUGIN_BASE_URL}/on-alert`,
  }
};


export const cronJobsTimes =
  [
    { label: 'Minute', value: '* * * * *' },
    { label: 'Hourly', value: '0 * * * *' },
    { label: 'Daily', value: '0 0 * * *' },
    { label: 'Weekly', value: '0 0 * * 0' },
    { label: 'Monthly', value: '0 0 1 * *' },
    { label: 'Custom', value: '' }
  ]


export const pluginBackendUrl = "api/plugins/dashboard-reporter-app/resources/";