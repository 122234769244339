import React from 'react';
import { PopUp } from 'helpers/PopUp';
import { Button, useStyles2 } from '@grafana/ui';
import DashboardDropdown from 'components/DashboardDropdown';
import { useDropdowns } from 'components/App/DropdownProvider';
import { defaultTimeZone } from 'utils/dashboardTimeSettings';
import { pluginBackendUrl } from '../../constants';
import DashboardOnDemandButton from './DashboardOnDemandButton';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';


export interface SelectOption {
    label: string;
    value: string;
}
export function OnDemand() {
    const s = useStyles2(getStyles);
    const { selectedDashboard, setSelectedDashboard } = useDropdowns();


    const generateReport = async () => {
        if (!selectedDashboard?.uid) {
            return;
        }

        try {
            const timeZone = defaultTimeZone(selectedDashboard?.timezone || '');
            const activeOrgId = localStorage.getItem('orgId');
            const reportUrl = `${pluginBackendUrl}report?dashUid=${selectedDashboard.uid}&timeZone=${timeZone}&timeRangeFrom=${selectedDashboard?.time?.from}&timeRangeTo=${selectedDashboard?.time?.to}&orgId=${activeOrgId}`
            window.open(reportUrl, '_blank');
            PopUp('Report generated successfully.', true);
            setSelectedDashboard(null);
        } catch (error) {
            PopUp('Error generating report.', false);
            console.error('Error generating report:', error);
        }
    };

    return (
        <div>

            <DashboardDropdown />
            <Button
                variant="primary"
                className={s.marginBottom}
                onClick={generateReport}
                disabled={!selectedDashboard?.uid}
            >
                Generate Report
            </Button>

            <DashboardOnDemandButton></DashboardOnDemandButton>

        </div>
    );
}

export default OnDemand;


const getStyles = (theme: GrafanaTheme2) => ({
    marginBottom: css`
    margin-bottom: ${theme.spacing(3)};
    `,
});