import React, { useEffect, useState } from 'react';
import { Button, Field, FieldSet, MultiSelect } from '@grafana/ui';
import { ExtendedDashboard, useDropdowns } from 'components/App/DropdownProvider';
import axios from 'axios';
import { DashboardLink } from '@grafana/schema';
import { pluginBackendUrl } from '../../constants';
import { PopUp } from 'helpers/PopUp';
import { defaultTimeZone } from 'utils/dashboardTimeSettings';
import { DashboardProps } from '@grafana/data';

interface Tag {
    term: string  // Use optional properties if you're not sure
}


export function DashboardOnDemandButton() {
    const { dashboardOptions } = useDropdowns();
    const [selectedDashboards, setSelectedDashboards] = useState<DashboardProps[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [changesPending, setChangesPending] = useState(false);

    useEffect(() => {
        fetchAllDashboardTags();
    }, []);



    const fetchAllDashboardTags = async () => {
        try {
            const response = await axios.get<Tag[]>(`/api/dashboards/tags`);  // Specifying the expected return type from axios
            const tags = response.data.map(tag => tag.term);  // Correctly mapping over the data with types applied
            // Filter dashboards to see which ones have a tag that matches their UID
            const linkedDashboards = dashboardOptions.filter((dashboard: DashboardProps) => dashboard.uid && tags.includes(dashboard.uid));
            setSelectedDashboards(linkedDashboards);
            setSelectedTags(tags);
        } catch (err) {
            console.error('Error fetching dashboard tags:', err);
            PopUp('Failed to process dashboard tag data.', false);
        }
    }

    const fetchDashboardData = async (dashboardsUid: string) => {
        try {
            const response = await axios.get(`/api/dashboards/uid/${dashboardsUid}`);
            return response.data.dashboard as ExtendedDashboard;
        } catch (err) {
            PopUp('Failed to process dashboard data.', false);
            return
        }
    };



    // Function to handle adding a link and tag
    const applyChanges = async () => {
        const dashboardsToAddLink = selectedDashboards.filter((dashboard) => !selectedDashboards.includes(dashboard.uid as any));


        const dashboardsToRemoveLink = dashboardOptions.filter(dashboard => selectedTags.includes(dashboard.uid));

        // Fetch data only for dashboards where changes are needed
        const dashboardsToUpdate = [...dashboardsToAddLink, ...dashboardsToRemoveLink];
        const dashboardDetailsPromises = dashboardsToUpdate.map(dashboard => fetchDashboardData(dashboard.uid));

        try {
            const dashboardDetails = await Promise.all(dashboardDetailsPromises);

            const updates = dashboardDetails.map(async (dashboard: any) => {
                const isAdding = dashboardsToAddLink.map(d => d.uid).includes(dashboard.uid);
                const existingLinkIndex = dashboard.links.findIndex((link: DashboardLink) => link.title === "Report");

                if (isAdding) {
                    if (existingLinkIndex === -1) {
                        return addLinkAndTag(dashboard);
                    }
                } else {
                    if (existingLinkIndex !== -1) {
                        return removeLinkAndTag(dashboard, existingLinkIndex);
                    }
                }
                return null;
            });

            const updatedDashboardUids = (await Promise.all(updates)).filter(uid => uid !== null);
            if (updatedDashboardUids.length > 0) {
                PopUp('Changes applied to selected dashboards successfully.', true);
            } else {
                PopUp('No changes were applied.', false);
            }
        } catch (error) {
            console.error('Error applying changes:', error);
            PopUp('Failed to apply changes.', false);
        }
        setChangesPending(false);
    };

    const addLinkAndTag = async (dashboard: ExtendedDashboard) => {
        const newLink = createNewLink(dashboard) as DashboardLink;
        dashboard?.links?.push(newLink);
        dashboard.hasLink = true;
        dashboard.tags.push(dashboard.uid.toString());
        return updateDashboard(dashboard, "Added report link");
    };

    const removeLinkAndTag = async (dashboard: ExtendedDashboard, linkIndex: number) => {
        dashboard.links?.splice(linkIndex, 1);
        dashboard.hasLink = false;
        dashboard.tags = dashboard.tags.filter(tag => tag !== dashboard.uid.toString());
        return updateDashboard(dashboard, "Removed report link");
    };

    const updateDashboard = async (dashboard: ExtendedDashboard, message: string) => {
        const updatedDashboard = {
            dashboard: dashboard,
            message: message,
            overwrite: true
        };
        await axios.post('/api/dashboards/db', updatedDashboard);
        return dashboard.uid;
    };

    // Helper to create a new link
    const createNewLink = (dashboard: any) => ({
        asDropdown: false,
        icon: "external link",
        includeVars: true,
        keepTime: true,
        tags: [],
        targetBlank: true,
        title: "Report",
        tooltip: "",
        type: "link",
        url: `${pluginBackendUrl}report?dashUid=${dashboard.uid}&timeZone=${defaultTimeZone(dashboard.timezone)}&timeRangeFrom=${dashboard.time?.from}&timeRangeTo=${dashboard.time?.to}&orgId=${localStorage.getItem('orgId')}`
    });


    return (
        <FieldSet>
            <Field label="Report Button on Dashboards" description="Select dashboards on which to show report button to generate on demand" >
                <MultiSelect
                    isMulti
                    options={dashboardOptions}
                    onChange={(values) => {
                        setSelectedDashboards(values as DashboardProps[]);
                        setChangesPending(true);
                    }}
                    value={selectedDashboards}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    width={60}
                />
            </Field>
            <Button
                variant="primary"
                onClick={applyChanges}
                disabled={!changesPending} // Disable button if no changes are pending
            >
                Update
            </Button>
        </FieldSet>
    );
}

export default DashboardOnDemandButton;
