import React from 'react';
import axios from 'axios';
import { PopUp } from 'helpers/PopUp';
import { Button, useStyles2 } from '@grafana/ui';
import CustomTable from 'components/CustomComponents/CustomTable';
import { cronToHumanReadable } from 'helpers/CronToHumanReadable';
import { Report } from 'pages/OnPeriod';
import { ContactPoint } from './ContactPointsReport';
import { useDropdowns } from './App/DropdownProvider';
import { css } from '@emotion/css';

interface TimeRange {
    From: string;
    To: string;
}


interface ScheduledReportsProps {
    reports: Report[];
    setReports: (reports: Report[]) => void;
}

export function ScheduledReports({ reports, setReports }: ScheduledReportsProps): JSX.Element {
    const s = useStyles2(getStyles);

    const { setSelectedDashboard, setSelectedContactPoint, setCronExpression, setIsUpdating, isUpdating } = useDropdowns();

    const activeOrgId = localStorage.getItem('orgId');

    const handleRemove = async (configIdentifier: string) => {
        try {
            await axios.delete(`api/plugins/dashboard-reporter-app/resources/remove-scheduled-report?dashUid=${configIdentifier}`);
            const removedReport = reports.find(report => report.configIdentifier === configIdentifier) ? reports.filter(report => report.configIdentifier !== configIdentifier) : [];
            setReports(removedReport)
            PopUp('Scheduled report removed successfully.', true);
        } catch (error) {
            console.error(error);
            PopUp('Error removing report.', false);
        }
    };



    const updateReport = (dashboard: Report) => {
        const updatedDashboard = {
            uid: dashboard?.dashUID,
            title: dashboard?.dashTitle,
            configIdentifier: dashboard?.configIdentifier,
            time: {
                from: dashboard?.timeRange.From,
                to: dashboard?.timeRange?.To
            },
        };
        setIsUpdating(true);
        // Any because Report(coming from BE...) is not compatible with Dashboard
        setSelectedDashboard(updatedDashboard as any);
        setSelectedContactPoint(dashboard?.contactPoint);
        setCronExpression(dashboard?.scheduleTime == undefined ? '' : dashboard?.scheduleTime);

    };

    const stopEditing = () => {
        setIsUpdating(false);
        setSelectedDashboard(null);
        setSelectedContactPoint(null);
        setCronExpression("");  // Reset or set to a default value
    };


    const columns = [
        {
            title: 'Dashboard Title',
            dataIndex: 'dashTitle',
            key: 'dashTitle',
            render: (text: string) => text || 'N/A'
        },
        {
            title: 'Schedule',
            dataIndex: 'scheduleTime',
            key: 'scheduleTime',
            render: (cronExpression: string) => cronToHumanReadable(cronExpression)
        },
        {
            title: 'Time Range',
            dataIndex: 'timeRange',
            key: 'timeRange',
            render: (timeRange: TimeRange) => `${timeRange?.From} to ${timeRange?.To}`
        },
        {
            title: 'Contact Point',
            dataIndex: 'contactPoint',
            key: 'contactPoint',
            render: (contactPoint: ContactPoint) => `${contactPoint?.label} (${contactPoint?.type})`
        },
        {
            title: 'Emails',
            key: 'emails',
            render: (contactPoint: Report) => {
                return contactPoint?.contactPoint?.settings?.addresses || 'No email provided';
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            className: 'text-center',
            render: (record: Report) => (
                <>
                    <Button key="remove" onClick={() => handleRemove(record.configIdentifier)}>
                        Remove
                    </Button>
                    <span className={s.paddingLeft}></span>
                    <Button className={'padding-left'} onClick={() => { isUpdating ? stopEditing() : updateReport(record) }}>
                        {isUpdating ? 'Stop Editing' : 'Edit'}
                    </Button>
                </>
            ),
        }
    ];



    const filterFunction = (item: Report) => item.orgId === activeOrgId;

    return (

        <div className={s.paddingTop}>
            <CustomTable
                contactPoints={reports || []}
                dataTable={reports || []}
                handleRemove={handleRemove}
                columns={columns}
                filterFunction={filterFunction}
                propertyKey="configIdentifier"
            />
        </div>
    );
}

const getStyles = () => ({
    paddingLeft: css`
    padding-left: 20px;
  `,
    paddingTop: css`
    padding-top: 10px;
    `,
});

export default ScheduledReports;
