import axios from 'axios';
import { ContactPoint } from 'components/ContactPointsReport';
import { PopUp } from 'helpers/PopUp';

export const getContactPointEmails = async (selectedContactPointUid: string) => {
    try {
        const contactPointsResponse = await axios.get(`/api/v1/provisioning/contact-points`);
        const contactPoints = contactPointsResponse.data;

        const selectedContactPoint = contactPoints.find((contactPoint: ContactPoint) => contactPoint.uid === selectedContactPointUid);
        // Assuming the addresses are stored under settings and it is an array of email addresses

        let emailAddresses = selectedContactPoint.settings.addresses;

        // Replace all semicolons with commas
        emailAddresses = emailAddresses.replace(/;/g, ',');

        return emailAddresses;
    } catch (error) {
        PopUp('Error fetching contact points', false);
        return '';
    }
};
