import React from "react";
import { Button } from "@grafana/ui";
import { useDropdowns } from "components/App/DropdownProvider";
import { Report } from "pages/OnPeriod/OnPeriod";
import { scheduleReport } from "utils/scheduleReportUtil";
import CronJobScheduler from "./CronJobScheduler";

interface CronJobSchedulerWrapperProps {
    s: any;
    fetchReports: () => void;
    reports: Report[];
}


export const CronJobSchedulerWrapper = ({ s, fetchReports, reports }: CronJobSchedulerWrapperProps) => {
    const { selectedDashboard, setSelectedDashboard, setSelectedContactPoint, selectedContactPoint, cronExpression, setCronExpression, isUpdating, setIsUpdating } = useDropdowns();


    const clearSelections = () => {
        setSelectedDashboard(null);
        setSelectedContactPoint(null);
        setCronExpression(null);
    };

    const handleScheduleClick = () => {

        scheduleReport(selectedDashboard, selectedContactPoint, cronExpression, fetchReports, () => {
            setSelectedDashboard(null);
            clearSelections();
            setCronExpression(null);
            setIsUpdating(false);
        });
    };

    return (
        <div className={s.marginTop}>

            <CronJobScheduler s={s} reports={reports} />
            <Button
                onClick={handleScheduleClick}
                variant="primary"
            >
                {isUpdating ? 'Update Report' : 'Schedule Report'}
            </Button>
        </div>
    );
};

export default CronJobSchedulerWrapper;
